import { useEffect } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { useAppState } from '../../state';

const useLessonLog = () => {
  const { lessonInfo } = useAppState();
  const sendLogEnteringLesson = () => {
    if (lessonInfo.type === 'learner') {
      requestServerApi<any>(() =>
        serverApi.mobileAppControllerCreateLessonEnteringLogLearner(
          lessonInfo.userId,
          lessonInfo.companyId,
          lessonInfo.lessonId,
          {
            eventTimestamp: new Date().getTime(),
          }
        )
      );
    } else {
      // TODO 教師ログ追加
    }
  };
  return { sendLogEnteringLesson };
};
export default useLessonLog;
