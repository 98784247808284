import { useCallback } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { RoomType } from '../../types';
import { IDENTITY_SUFFIX } from '../../constants';

export function getTwilioAccessToken(
  userid: string,
  companyId: string,
  identity: string,
  roomName: string
): Promise<{ data: string }> {
  const response = requestServerApi<any>(() =>
    serverApi
    .organizationsControllerGetTwilioAccessToken(
      companyId,
      identity + `${IDENTITY_SUFFIX}${userid}`,
      userid,
      roomName
    )
    .catch(e => null)
  );
  return response;
}
export default function useHanasuPlusAuth() {
  const getTokenHanasuPlus = useCallback((name: string, room: string, companyId: string, userId: string): Promise<{
    room_type: RoomType;
    token: string;
  }> => {
    return getTwilioAccessToken(userId, companyId, name, room).then(res => {
      if (res.data) {
        return {
          room_type: 'group',
          token: res.data,
        };
      } else {
        return Promise.reject(new Error('token error'));
      }
    });
  }, []);

  return { getTokenHanasuPlus };
}
