import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { getToken, requestServerApi, serverApi } from '../../api/api';
import { REACT_APP_CHAT_WEBSOCKET_URL } from '../../configs';
import { useAppState } from '../../state';
import dayjs from 'dayjs';
import useInterval from '../../hooks/useInterval/useInterval';

export type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  chatClient: Cli;
  messages: msgObj[];
};
export interface Cli {
  sendMessage: (message: string) => void;
  getMessages: () => msgObj[];
}
export interface msgObj {
  action: 'sendMessage';
  type: string; // 'teacher' | 'learner';
  username: string;
  messageContent: string;
  dateTime: Date;
  roomId: string;
  companyId: string;
  token: string;
  userId: string;
}
interface joinObj {
  action: 'joinRoom';
  type: string; //'teacher' | 'learner';
  roomId: string;
  companyId: string;
  token: string;
  userId: string;
}
export const ChatContext = createContext<ChatContextType>(null!);

/**
 * チャットプロバイダ　はなすプラス用
 *
 * @param param0
 * @returns
 */
export const ChatProvider: React.FC = ({ children }) => {
  const { lessonInfo } = useAppState();
  const { room } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const localParticipant = room?.localParticipant;
  const [messages, setMessages] = useState<msgObj[]>([]);
  const [ws, setWs] = useState<WebSocket>(new WebSocket(REACT_APP_CHAT_WEBSOCKET_URL));
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  // 切断防止のため一定時間ごとに空のメッセージを送る
  // FIXME 切断時の再接続が上手く機能していないため暫定対応としていれている
  useInterval(() => {
    chatClient.sendMessage('');
  }, 1000 * 10);

  const chatClient = {
    sendMessage: async (message: string) => {
      const token = await getToken();
      setToken(token);

      const username = localParticipant?.identity ?? '';
      const messageObj: msgObj = {
        action: 'sendMessage',
        type: lessonInfo.type,
        roomId: lessonInfo.roomName,
        username: username,
        messageContent: message,
        dateTime: new Date(),
        companyId: lessonInfo.companyId,
        token: token,
        userId: lessonInfo.userId,
      };
      ws.send(JSON.stringify(messageObj));

      /*
        DB登録 
        ここで登録する理由
        - AWSに到達できずに送信できないメッセージも保存するため
       */
      requestServerApi(() =>
        serverApi.organizationsControllerPutCompanyRoomChat(lessonInfo.companyId, lessonInfo.roomName, {
          lesson_id: lessonInfo.lessonId,
          message,
          receiver_id: '', //FIXME 削除
          sender_id: '',
        })
      );

      //
      if (message) {
        setMessages(old => [...old, messageObj]);
      }
    },
    getMessages: () => {
      return messages;
    },
  };

  const connect = useCallback(
    (joinToken: string) => {
      setToken(joinToken);
      const join: joinObj = {
        action: 'joinRoom',
        type: lessonInfo.type,
        roomId: lessonInfo.roomName,
        companyId: lessonInfo.companyId,
        token: joinToken,
        userId: lessonInfo.userId,
      };
      if (ws.readyState === ws.OPEN) {
        ws.send(JSON.stringify(join));
      }
      ws.onmessage = (event: MessageEvent) => {
        console.log('受信', { event }, messages);
        const data = JSON.parse(event.data);
        const messageObj: msgObj = {
          action: 'sendMessage',
          type: lessonInfo.type,
          roomId: lessonInfo.roomName,
          username: data.username,
          messageContent: data.messageContent,
          dateTime: new Date(data.dateTime),
          companyId: lessonInfo.companyId,
          token: joinToken,
          userId: lessonInfo.userId,
        };

        // 自分がおくったメッセージは送信時にリストに追加済みのため、追加しない。
        // 送信時に追加できなった時のために念のため時間が違うメッセージがあったら追加
        setMessages(old => {
          const isSendedMessage = old.some(m => {
            return m.username === messageObj.username && dayjs(m.dateTime).diff(dayjs(messageObj.dateTime)) === 0;
          });
          if (isSendedMessage) {
            return old;
          } else {
            return [...old, messageObj];
          }
        });
      };

      ws.onerror = event => {
        console.log('Error', { event });
      };
      /**
       * 接続時
       */
      ws.onopen = () => {
        // 再接続時に呼ばれる
        ws.send(JSON.stringify(join));
      };

      /**
       * 切断時
       */
      ws.onclose = event => {
        // 何も操作がないと数分で切断されてしまうため切断されたら再接続
        const socket = new WebSocket(REACT_APP_CHAT_WEBSOCKET_URL);
        setWs(socket);
        console.log('reconnect');
      };
    },
    [lessonInfo, messages, ws]
  );

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        connect,
        hasUnreadMessages,
        messages,
        chatClient,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
