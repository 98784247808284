import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { VideoApp } from '../../..';
import { useEffect } from 'react';
import { useAppState } from '../../../state';
import ErrorDialog from '../../ErrorDialog/ErrorDialog';

export default function HanasuPlusRoutes() {
  return (
    <>
      <Switch>
        <Route path="/join-room/:roomId">
          <VideoApp />
        </Route>
        <Route path="*">
          <RedirectJoinRoom />
        </Route>
      </Switch>
    </>
  );
}
const RedirectJoinRoom = () => {
  const { error, setError } = useAppState();
  const search = useLocation().search;
  const history = useHistory();
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const roomId = queryParams.get('roomId') ?? '';
    if (roomId) {
      history.push(`/join-room/${roomId}/` + search);
    } else {
      setError(new Error('Room connection failed.'));
    }
  }, [history, search, setError]);
  const handleDismissError = () => {
    setError(null);
    window.close();
  };
  return (
    <>
      <ErrorDialog dismissError={handleDismissError} error={error} />
    </>
  );
};
