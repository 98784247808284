import { useEffect, useState } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { REACT_APP_IS_DEMO_MODE } from '../../configs';
import useError from '../useError/useError';

interface Lesson {
  companyId: string;
  roomName: string;
}
export default function useLesson({ companyId, roomName }: Lesson) {
  // FIXME 型が定義されたら変更
  const [lesson, setLesson] = useState<any>({});
  const { sendErrorLog } = useError();

  useEffect(() => {
    if (REACT_APP_IS_DEMO_MODE) {
      return;
    }
    if (!companyId || !roomName) {
      return;
    }
    const request = async () => {
      const response = await requestServerApi<any>(() =>
        serverApi.organizationsControllerGetLessonByRoomId(companyId, roomName)
      );

      try {
        if (response.data.data && Object.keys(response.data.data[0]).length > 0) {
          setLesson(response.data.data[0]);
        }
      } catch (error) {
        sendErrorLog({
          error: ((error as any).stack ? error : error) + '\n' + JSON.stringify(response),
          errorCode: '',
          message: 'Lesson情報を取得できませんでした',
        });
      }
    };

    request().catch(e => console.error('useLesson', { companyId, roomName }, e));
  }, [companyId, roomName]);

  return { lesson };
}
