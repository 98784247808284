import { styled } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import { IVideoTrack } from '../../../types';

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  setPiPVideos: React.Dispatch<React.SetStateAction<HTMLVideoElement[]>>;
}
const Video = styled('video')({
  width: '100%',
  height: '100%',
});
export default function PiPVideoTrack({ track, setPiPVideos }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  useMediaStreamTrack(track);
  useEffect(() => {
    const el = ref.current;
    el.muted = true;

    track.attach(el);
    setPiPVideos(old => [...old, el]);
    return () => {
      track.detach(el);
      el.srcObject = null;
      setPiPVideos(old => old.filter(x => x !== el));
    };
  }, [track, setPiPVideos]);
  return <Video ref={ref} className={`pip-video-${track.name}`} />;
}
