import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute/PrivateRoute';
import LoginPage from '../../LoginPage/LoginPage';
import { VideoApp } from '../../..';

export default function DemoRoutes() {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <VideoApp />
      </PrivateRoute>
      <PrivateRoute path="/room/:URLRoomName">
        <VideoApp />
      </PrivateRoute>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}
