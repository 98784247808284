import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { LessonInfo, useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { REACT_APP_IS_DEMO_MODE } from '../../configs';
import useURLParams from '../../hooks/useURLParams/useURLParams';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user, setLessonInfo, lessonInfo, setError } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();
  const getParams = useURLParams();

  useEffect(() => {
    const path = document.location.pathname;

    // はなすプラス用の場合は接続後、次のステップに自動的に進める
    if (path.includes('/join-room') && !REACT_APP_IS_DEMO_MODE) {
      const { companyId, identity, roomId, userId: paramUserId, lessonId, type, lang } = getParams();
      setName(identity);
      setRoomName(roomId);
      setUserId(paramUserId);
      const typeValue = (() => {
        if (type === 'teacher' || type === 'learner') {
          return type;
        } else {
          return '';
        }
      })();
      setLessonInfo(old =>
        Object.assign<{}, LessonInfo, LessonInfo>({}, old, {
          companyId,
          lessonId,
          roomName: roomId,
          userId: paramUserId,
          type: typeValue,
          lang,
        })
      );
      setStep(Steps.deviceSelectionStep);
      if (!companyId || !identity || !lessonId) {
        setError(new Error('Failed to retrieve lesson information.'));
      }
    }
  }, [getParams, setError, setLessonInfo]);

  useEffect(() => {
    if (URLRoomName) {
      const prefix = REACT_APP_IS_DEMO_MODE && !URLRoomName.includes('DEMO_') ? 'DEMO_' : '';
      setRoomName(prefix + URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomName={roomName}
          setStep={setStep}
          companyId={lessonInfo.companyId}
          userId={userId}
        />
      )}
    </IntroContainer>
  );
}
