import useVideoContext from '../useVideoContext/useVideoContext';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useParticipants from '../useParticipants/useParticipants';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { useAppState } from '../../state';
import { IDENTITY_SUFFIX } from '../../constants';

export default function useMainParticipant() {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;
  const { lessonInfo } = useAppState();

  // 自身が学習者の場合、教師をメインとする
  const perticipantTeacher = (() => {
    if (lessonInfo.type === 'learner' && participants.length > 0) {
      return participants.find(e => {
        if (lessonInfo.detail) {
          return lessonInfo.detail?.teachers.some(
            t => t.nick_name + `${IDENTITY_SUFFIX}${t.teacher_id}` === e.identity
          );
        } else {
          return false;
        }
      });
    }
  })();

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    remoteScreenShareParticipant ||
    perticipantTeacher ||
    dominantSpeaker ||
    participants[0] ||
    localParticipant
  );
}
