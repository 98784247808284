import logoTate from '../../_assets/images/logo01_tate.png';
import logo from '../../_assets/images/logo01.png';
import { Theme, useMediaQuery } from '@material-ui/core';

export default function HanasuPlusLogo({ className }: { className: string }) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <img src={isMobile ? logo : logoTate} alt="logo" className={className} />
    </>
  );
}
