import { makeStyles, Theme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import useInterval from '../../hooks/useInterval/useInterval';
import { useAppState } from '../../state';
import { REACT_APP_IS_DEMO_MODE } from '../../configs';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
const useStyles = makeStyles((theme: Theme) => ({
  enabled: {
    position: 'absolute',
    zIndex: 100,
    top: '8%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: 'larger',
    fontWeight: 500,
  },
  disabled: {
    display: 'none',
  },
  finished: {
    color: 'yellow',
  },
  finishedOver30sec: {
    color: 'red',
  },
}));
enum TimerSteps {
  hidden,
  remaining3min,
  finished,
  finishedOver30sec,
  closed,
}

/**
 * 3分前：タイマー表示
 *
 * 1分後：強制終了
 * @returns
 */
export default function Timer() {
  const { videoEndTime } = useAppState();
  const [timerStep, setTimerStep] = useState<TimerSteps>(TimerSteps.hidden);
  const [remainingSecondsToClose, setRemainingSecondsToClose] = useState(60);
  const { room } = useVideoContext();
  const [timerDuration, setTimerDuration] = useState(0);
  const Lang = LanguageSelector();

  useEffect(() => {
    // デフォルト３分前にタイマー表示
    let timerSecond = 3 * 60;
    const now = dayjs();
    const remaing = videoEndTime.diff(now) / 1000;
    setTimerDuration(remaing < timerSecond ? remaing : timerSecond);
  }, [videoEndTime]);

  const onEnded = () => {
    room!.disconnect();
    if (REACT_APP_IS_DEMO_MODE) {
      window.location.href = '/';
    } else {
      window.close();
    }
  };

  // 1秒ごとに時間を確認
  useInterval(() => {
    const now = dayjs();
    if (timerStep === TimerSteps.finished || timerStep === TimerSteps.finishedOver30sec) {
      setRemainingSecondsToClose(60 - Math.floor(now.diff(videoEndTime) / 1000));
    }
    if (now.add(-1, 'm').diff(videoEndTime) >= 0) {
      setTimerStep(TimerSteps.closed);
      onEnded();
    } else if (now.add(-30, 's').diff(videoEndTime) >= 0) {
      setTimerStep(TimerSteps.finishedOver30sec);
    } else if (now.diff(videoEndTime) >= 0) {
      setTimerStep(TimerSteps.finished);
    } else if (now.add(timerDuration, 's').diff(videoEndTime) >= 0) {
      setTimerStep(TimerSteps.remaining3min);
    }
  }, 1000);

  const getMinSecFormat = (remainingTime: number) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = Math.floor(remainingTime % 60);
    return `${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
  };

  const classes = useStyles();

  return (
    <div className={timerStep === TimerSteps.hidden ? classes.disabled : classes.enabled}>
      {timerStep === TimerSteps.remaining3min && (
        <CountdownCircleTimer
          isPlaying
          duration={timerDuration}
          colors="#4E9F3D" // circle border color - green
          size={75}
          strokeWidth={5}
        >
          {({ remainingTime }) => getMinSecFormat(remainingTime)}
        </CountdownCircleTimer>
      )}
      {(timerStep === TimerSteps.finished ||
        timerStep === TimerSteps.finishedOver30sec ||
        timerStep === TimerSteps.closed) && (
        <div className={timerStep === TimerSteps.finished ? classes.finished : classes.finishedOver30sec}>
          {Lang.KT_0100_21} {remainingSecondsToClose} {Lang.KT_0100_22}
        </div>
      )}
    </div>
  );
}
