import { Participant } from 'twilio-video';
import PiPParticipantTracks from '../PiPParticipantTracks/PiPParticipantTracks';

interface ParticipantProps {
  participant: Participant;
  setPiPVideos: React.Dispatch<React.SetStateAction<HTMLVideoElement[]>>;
}
export default function PiPParticipant({ participant, setPiPVideos }: ParticipantProps) {
  return <PiPParticipantTracks participant={participant} setPiPVideos={setPiPVideos} />;
}
