import React from 'react';

export default function StartRecordingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fill="#707578">
          <path
            d="M14.714 5.286c2.604 2.603 2.604 6.825 0 9.428-2.603 2.604-6.825 2.604-9.428 0-2.604-2.603-2.604-6.825 0-9.428 2.603-2.604 6.825-2.604 9.428 0zm-8.703.725c-2.203 2.203-2.203 5.775 0 7.978 2.203 2.203 5.775 2.203 7.978 0 2.142-2.142 2.201-5.577.178-7.791l-.178-.187-.187-.178c-2.214-2.023-5.649-1.964-7.79.178zM10 5.833c2.301 0 4.167 1.866 4.167 4.167 0 2.301-1.866 4.167-4.167 4.167-2.301 0-4.167-1.866-4.167-4.167C5.833 7.699 7.7 5.833 10 5.833z"
            transform="translate(-175 -158) translate(150 141) translate(1 9) translate(24 8)"
          />
        </g>
      </g>
    </svg>
  );
}
