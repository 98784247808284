import { useEffect, useState } from 'react';
import useLesson from '../useLesson/useLesson';
import { useAppState } from '../../state';
import { REACT_APP_IS_DEMO_MODE } from '../../configs';
import useError from '../useError/useError';

export default function useIsRoomEntry() {
  const [isNotRoomEntry, setIsNotRoomEntry] = useState<boolean>(true);
  const { lessonInfo } = useAppState();
  const { lesson } = useLesson({ companyId: lessonInfo.companyId, roomName: lessonInfo.roomName });
  const { sendErrorLog } = useError();
  // TODO 画面更新なしで有効にする場合はコールバックにする
  useEffect(() => {
    if (REACT_APP_IS_DEMO_MODE) {
      // デモモードの時は常に入ることができる
      setIsNotRoomEntry(false);
    } else {
      if (!lesson || Object.keys(lesson).length === 0) {
        return;
      }
      const lessonStartDatetime = new Date(lesson.start_datetime);
      const lessonEndDatetime = new Date(lesson.end_datetime);
      const now = new Date();

      const startDiff = (lessonStartDatetime.getTime() - now.getTime()) / 1000;
      const endDiff = (lessonEndDatetime.getTime() - now.getTime()) / 1000;

      //enable button on lesson day and before 5 minuites of lesson
      if (startDiff <= 5 * 60 && endDiff >= 0) {
        setIsNotRoomEntry(false);
      } else {
        sendErrorLog({
          error:
            'ルーム入室拒否 ' +
            JSON.stringify({
              now,
              startDiff,
              endDiff,
              lesson,
            }),
          errorCode: '',
          message: 'ルーム入室は許可されませんでした。',
        });
      }
    }
  }, [lesson]);
  return { isNotRoomEntry };
}
