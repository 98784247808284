import { Participant } from 'twilio-video';
import usePublications from '../../../hooks/usePublications/usePublications';
import PiPPublication from '../PiPPublication/PiPPublication';

interface ParticipantTracksProps {
  participant: Participant;

  setPiPVideos: React.Dispatch<React.SetStateAction<HTMLVideoElement[]>>;
}
export default function PiPParticipantTracks({ participant, setPiPVideos }: ParticipantTracksProps) {
  const publications = usePublications(participant);
  let filteredPublications;

  // 画面共有トラックはPiPに不要
  if (publications.some(p => p.trackName.includes('screen'))) {
    filteredPublications = publications.filter(p => !p.trackName.includes('screen'));
  } else {
    filteredPublications = publications;
  }

  return (
    <>
      {filteredPublications.map(publication => {
        return <PiPPublication publication={publication} participant={participant} setPiPVideos={setPiPVideos} />;
      })}
    </>
  );
}
