import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { requestServerApi, serverApi } from '../../api/api';
import { ERROR_CODE } from '../../constant/errorCode';

const useError = () => {
  const location = useLocation();
  const sendErrorLog = useCallback(
    (errorInfo: { message: string; errorCode: string; error: any }) => {
      const currentPage = location.pathname;
      const { error, errorCode, message } = errorInfo;

      requestServerApi<any>(() => {
        serverApi.organizationsControllerCreateErrorLog({
          source: 'ksco-video',
          currentPage: currentPage,
          userAgent: navigator.userAgent,
          error: typeof error === 'string' ? error : Object.keys(error).length > 0 ? JSON.stringify(error) : `${error}`,
          errorCode,
          message,
        });
      }).catch(err => {
        // 無限ループを避ける
        if (errorCode === ERROR_CODE.E_9999) {
          return;
        }
        sendErrorLog({
          error: err,
          errorCode: ERROR_CODE.E_9999,
          message: `ユーザーイベントログ作成でエラー`,
        });
      });
    },
    [location.pathname, requestServerApi, serverApi]
  );
  return { sendErrorLog };
};
export default useError;
