import { useEffect } from 'react';

/**
 *
 * @param callback
 * @param delay
 */
export default function useInterval(callback: () => void, delay: number = 0) {
  useEffect(() => {
    const interval = setInterval(() => {
      callback();
    }, delay);
    return () => clearInterval(interval);
  }, [callback, delay]);
}
