import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// FIXME ページによって取得するものをわける 型も定義して
export default function useURLParams() {
  const search = useLocation().search;
  const getParams = useCallback(() => {
    const queryParams = new URLSearchParams(search);
    // if (search.includes('join-room')) {
    const userId = queryParams.get('userId') ?? '';
    const companyId = queryParams.get('companyId') ?? '';
    const roomId = queryParams.get('roomId') ?? '';
    const identity = queryParams.get('identity') ?? '';
    const lessonId = queryParams.get('lessonId') ?? '';
    const type = queryParams.get('type') ?? '';
    const lang = queryParams.get('lang') ?? 'ja';
    return { userId, companyId, roomId, identity, lessonId, type, lang };
    // } else {
    //   return {};
    // }
  }, [search]);
  return getParams;
}
