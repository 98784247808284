import { useRef, useCallback, useEffect } from 'react';

export default function useAnimationFrame(isRunning: boolean, callback = () => {}) {
  const reqIdRef = useRef<number>(0);
  const loop = useCallback(() => {
    if (isRunning) {
      reqIdRef.current = requestAnimationFrame(loop);
      callback();
    }
  }, [isRunning, callback]);

  useEffect(() => {
    reqIdRef.current = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(reqIdRef.current);
  }, [loop]);
}
