import { useCallback } from 'react';
import { Room } from 'twilio-video';
import { pipVideoId } from '../../components/PiPView/PiPView';

export default function usePiPVideo(room: Room | null) {
  const startPiP = useCallback(() => {
    // FIXME Refから持ってこれる？
    const video = document.getElementById(pipVideoId) as HTMLVideoElement;
    endPiP();
    video!.requestPictureInPicture();
    video!.onended = () => {
      document.exitPictureInPicture();
    };
  }, []);

  const endPiP = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  };
  return { startPiP, endPiP };
}
