import { useState, useCallback, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import usePiPVideo from '../../../hooks/usePiPStart/usePiPStart';
import { ErrorCallback } from '../../../types';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle(room: Room | null, onError: ErrorCallback) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);
  const stopScreenShareAudioRef = useRef<() => void>(() => {});
  const { startPiP, endPiP } = usePiPVideo(room);
  const shareScreen = useCallback(() => {
    startPiP();
    navigator.mediaDevices
      .getDisplayMedia({
        audio: true,
        video: true,
        // preferCurrentTab: true,
      })
      .then(stream => {
        const videoTrack = stream.getVideoTracks()[0];
        const audioTrack = stream.getAudioTracks()[0];

        // 音声が共有されたときのみ音声の処理を実施する。
        if (audioTrack) {
          const audioCtx = new AudioContext();
          const localParticipantAudios = Array.from(room!.localParticipant.audioTracks.values()).map(
            x => x.track.mediaStreamTrack
          );
          const dest = audioCtx.createMediaStreamDestination();
          const gainNode = audioCtx.createGain();
          [...localParticipantAudios, audioTrack].forEach(track => {
            const mediaStream = new MediaStream([track]);
            const source = audioCtx.createMediaStreamSource(mediaStream);
            source.connect(gainNode);
          });
          gainNode.connect(dest);
          const joinedAudioTrack = dest.stream.getAudioTracks()[0];

          // originalAudioTrack.track.stop();

          // 共有音声トラックを参加させても送信できないので、元の音声を停止して合成した音声を送る

          room!.localParticipant
            .publishTrack(joinedAudioTrack, {
              name: 'screenAudio',
              priority: 'low',
            })
            .then(trackPublication => {
              const originalAudioTrack = Array.from(room!.localParticipant.audioTracks.values())[0];
              room!.localParticipant.unpublishTrack(originalAudioTrack.track);

              stopScreenShareAudioRef.current = () => {
                room!.localParticipant.unpublishTrack(joinedAudioTrack);
                // TODO: remove this if the SDK is updated to emit this event
                room!.localParticipant.emit('trackUnpublished', trackPublication);
                joinedAudioTrack.stop();
                audioTrack.stop();
                setIsSharing(false);
                room!.localParticipant.publishTrack(originalAudioTrack.track, {
                  name: originalAudioTrack.trackName,
                  priority: 'low',
                } as MediaStreamTrackPublishOptions);
              };

              // TODO ブラウザの共有停止ボタンでこちらが発火しないので、ビデオの停止で停止を実行している。
              // joinedAudioTrack.onended = stopScreenShareAudioRef.current;

              setIsSharing(true);
            })
            .catch(onError);
        }

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room!.localParticipant
          .publishTrack(videoTrack, {
            name: 'screen', // Tracks can be named to easily find them later
            priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
          } as MediaStreamTrackPublishOptions)
          .then(trackPublication => {
            stopScreenShareRef.current = () => {
              room!.localParticipant.unpublishTrack(videoTrack);
              // TODO: remove this if the SDK is updated to emit this event
              room!.localParticipant.emit('trackUnpublished', trackPublication);
              videoTrack.stop();
              setIsSharing(false);
            };

            videoTrack.onended = () => {
              stopScreenShareRef.current();
              // ブラウザの共有停止ボタンで停止してもaudioのonendedが反応しないのでvideoと同じタイミングで終了させる
              stopScreenShareAudioRef.current!();
              endPiP();
            };
            setIsSharing(true);
          })
          .catch(onError);
      })
      .catch(error => {
        // 共有画面選択時にキャンセルした場合は、PiPを閉じる
        endPiP();

        // Don't display an error if the user closes the screen share dialog
        if (
          error.message === 'Permission denied by system' ||
          (error.name !== 'AbortError' && error.name !== 'NotAllowedError')
        ) {
          console.error(error);
          onError(error);
        }
      });
  }, [room, onError, endPiP, startPiP]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing
        ? shareScreen()
        : (() => {
            stopScreenShareAudioRef.current();
            stopScreenShareRef.current!();
            endPiP();
          })();
    }
  }, [isSharing, shareScreen, room, endPiP]);

  return [isSharing, toggleScreenShare] as const;
}
