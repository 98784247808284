import { LocalTrackPublication, RemoteTrackPublication, Participant } from 'twilio-video';
import useTrack from '../../../hooks/useTrack/useTrack';
import { IVideoTrack } from '../../../types';
import PiPVideoTrack from '../PiPVideoTrack/PiPVideoTrack';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  setPiPVideos: React.Dispatch<React.SetStateAction<HTMLVideoElement[]>>;
}
export default function PiPPublication({ publication, setPiPVideos }: PublicationProps) {
  const track = useTrack(publication);
  if (!track) return null;
  switch (track.kind) {
    case 'video':
      return <PiPVideoTrack track={track as IVideoTrack} setPiPVideos={setPiPVideos} />;
    // All participant audio tracks are rendered in ParticipantAudioTracks.tsx
    default:
      return null;
  }
}
