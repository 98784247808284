import { useCallback, useEffect, useRef, useState } from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useAnimationFrame from '../../hooks/useAnimationFrame/useAnimationFrame';
import useParticipantsContext from '../../hooks/useParticipantsContext/useParticipantsContext';
import PiPParticipant from './PiPParticipant/PiPParticipant';

export const videoTrackClassName = 'track-video';
export const remoteVideoTrackClassName = 'remote-track-video';
export const screenShareButtonClassName = 'screen-share';
export const pipVideoId = 'pipvideo';
const Canvas = styled('canvas')({
  display: 'none',
  background: 'black',
});
const Video = styled('video')({
  display: 'none',
});
const Div = styled('div')({
  // これをnoneにするとPiPが真っ黒になる
  // display: 'none',
  position: 'absolute',
  top: 0,
  zIndex: 10,
});
const DivPiPParticipants = styled('div')({
  // これをnoneにするとPiPが真っ黒になる
  // display: 'none',
  width: '0',
});

export default function PiPView() {
  const { room } = useVideoContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasref = useRef<HTMLCanvasElement>(null);
  const wr = useRef<HTMLDivElement>(null);
  const participants = useParticipants();
  const [isDrawing, setIsDrawing] = useState(false);
  const trackWidth = 300;
  const trackHeight = trackWidth / 1.778; // 16:9
  const { galleryViewParticipants } = useParticipantsContext();
  const [pipVoides, setPiPVideos] = useState<HTMLVideoElement[]>([]);

  // TODO 参加者の動画のみ描画、名前等は描画していないので必要であれば修正
  const pipDraw = useCallback(() => {
    canvasref.current!.width = trackWidth;
    canvasref.current!.height = trackHeight * pipVoides.length;
    pipVoides.forEach((video, i) => {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('className', `track-${i}`);
      const ctx = canvas.getContext('2d');
      canvas.width = trackWidth;
      canvas.height = trackHeight;
      const expansionRatio = video.videoHeight / trackHeight;
      ctx?.clearRect(0, 0, canvas.width, canvas.height);

      ctx?.drawImage(
        video,
        0,
        0,
        video.videoWidth,
        video.videoHeight,
        (trackWidth - video.videoWidth / expansionRatio) / 2,
        0,
        video.videoWidth / expansionRatio,
        video.videoHeight / expansionRatio
      );

      const parentCtx = canvasref.current?.getContext('2d');
      parentCtx?.clearRect(0, trackHeight * i, trackWidth, trackHeight);
      // parentCtx?.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, trackHeight * i, trackWidth, trackHeight);
      parentCtx?.drawImage(canvas, 0, 0, trackWidth, trackHeight, 0, trackHeight * i, trackWidth, trackHeight);
    });
  }, [pipVoides, trackHeight]);
  useAnimationFrame(isDrawing, pipDraw);

  useEffect(() => {
    canvasref.current!.width = trackWidth;
    canvasref.current!.height = trackHeight * pipVoides.length;

    const video = videoRef.current;
    video!.srcObject = canvasref.current!.captureStream(60);
    video!.width = canvasref.current!.width;
    video!.height = canvasref.current!.height;
    video!.muted = true;
    video!.autoplay = true;
    video!.play();
    setIsDrawing(true);

    return () => {
      // video?.pause();
    };
  }, [room, participants, galleryViewParticipants, pipVoides, trackHeight]);

  return (
    <Div ref={wr}>
      <Video id={pipVideoId} ref={videoRef} playsInline autoPlay />
      <Canvas ref={canvasref} />
      {[room!.localParticipant, ...galleryViewParticipants].map(participant => {
        return (
          <DivPiPParticipants>
            <PiPParticipant participant={participant} setPiPVideos={setPiPVideos} />;
          </DivPiPParticipants>
        );
      })}
    </Div>
  );
}
